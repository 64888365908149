import * as React from "react"
import Layout from "../Layout"
const IndexPage = () => {
  return (
    <Layout>
      <h2>← Links til alle docs i /da-mappen på Google Drive</h2>
    </Layout>
  )
}

export default IndexPage
